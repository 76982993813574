:root {
  --primary-color: #02b0ff;
  --secondary-color: #0276ab;
  --auxiliary-color: #02B0FF;

  --header-link-color: #0299DE;

  --recent-icons-background: #C2E8F9;
  --recent-icons-color: #000;
  --recent-icons-border: #C2E8F9;

  --pix-keys-manage-color: #0299DE;
  --pix-keys-manage-button-background: transparent;
  --pix-keys-manage-button-color: #02B0FF;
  --pix-keys-manage-button-border: #02B0FF;

  --pix-modal-cancel-button-background: transparent;
  --pix-modal-cancel-button-color: #02B0FF;
  --pix-modal-cancel-button-border: #02B0FF;

  --pix-modal-confirm-button-background: #02B0FF;
  --pix-modal-confirm-button-color: #ffffff;
  --pix-modal-confirm-button-border: #02B0FF;

  --pix-transfer-confirm-button-background: #02B0FF;
  --pix-transfer-confirm-button-color: #ffffff;
  --pix-transfer-confirm-button-border: #02B0FF;

  --pix-transfer-contact-button-background: transparent;
  --pix-transfer-contact-button-color: #02B0FF;
  --pix-transfer-contact-button-border: #02B0FF;

  --pix-transfer-modal-cancel-button-background: transparent;
  --pix-transfer-modal-cancel-button-color: #02B0FF;
  --pix-transfer-modal-cancel-button-border: #02B0FF;

  --pix-transfer-modal-confirm-button-background: #02B0FF;
  --pix-transfer-modal-confirm-button-color: #ffffff;
  --pix-transfer-modal-confirm-button-border: #02B0FF;

  --pix-transfer-modal-input-background: #ffffff;
  --pix-transfer-modal-input-color: #000;
  --pix-transfer-modal-input-border: #000;

  --pix-limits-cancel-button-background: transparent;
  --pix-limits-cancel-button-color: #02B0FF;
  --pix-limits-cancel-button-border: #02B0FF;

  --pix-limits-confirm-button-background: #02B0FF;
  --pix-limits-confirm-button-color: #ffffff;
  --pix-limits-confirm-button-border: #02B0FF;

  --share-receipt-button-background: transparent;
  --share-receipt-button-color: #02B0FF;
  --share-receipt-button-border: #02B0FF;

  --ted-page-confirm-button-background: #02B0FF;
  --ted-page-confirm-button-color: #ffffff;
  --ted-page-confirm-button-border: #02B0FF;

  --ted-transfer-cancel-button-background: transparent;
  --ted-transfer-cancel-button-color: #02B0FF;
  --ted-transfer-cancel-button-border: #02B0FF;

  --ted-transfer-confirm-button-background: #02B0FF;
  --ted-transfer-confirm-button-color: #ffffff;
  --ted-transfer-confirm-button-border: #02B0FF;

  --pay-ticket-page-button-background: #02B0FF;
  --pay-ticket-page-button-color: #000;
  --pay-ticket-page-button-border: #000;

  --generate-ticket-page-button-background: transparent;
  --generate-ticket-page-button-color: #000;
  --generate-ticket-page-button-border: #000;

  --cancel-ticket-button-background: transparent;
  --cancel-ticket-button-color: #02B0FF;
  --cancel-ticket-button-border: #02B0FF;

  --confirm-ticket-button-background: #02B0FF;
  --confirm-ticket-button-color: #ffffff;
  --confirm-ticket-button-border: #02B0FF;

  --generate-ticket-select-background: #ffffff;
  --generate-ticket-select-color: #000;
  --generate-ticket-select-border: #000;

  --pay-ticket-cancel-button-background: transparent;
  --pay-ticket-cancel-button-color: #02B0FF;
  --pay-ticket-cancel-button-border: #02B0FF;

  --pay-ticket-confirm-button-background: #02B0FF;
  --pay-ticket-confirm-button-color: #ffffff;
  --pay-ticket-confirm-button-border: #02B0FF;

  --delete-photo-button-background: transparent;
  --delete-photo-button-color: #0299DE;
  --delete-photo-button-border: none;

  --update-info-settings-button-background: transparent;
  --update-info-settings-button-color: #02B0FF;
  --update-info-settings-button-border: #02B0FF;

  --change-password-button-background: #02B0FF;
  --change-password-button-color: #ffffff;
  --change-password-button-border: #02B0FF;

  --change-password-modal-button-background: #02B0FF;
  --change-password-modal-button-color: #ffffff;
  --change-password-modal-button-border: #02B0FF;

  --checkbox-border: #000;
  --checkbox-color: #000;

  --bg-color: #ffffff;
  --bg-secondary-color: #121212;
  --bg-auxiliary-color: #ededed;

  --bg-circle-color: #ffffff;
  --bg-circle-hover: #02B0FF;
  --bg-circle-text-color: #000;
  --bg-circle-text-color-hover: #000;

  --bg-modal-color: #ffffff;
  --bg-input-color: #ffffff;

  --dark-color: #3f3f3f;

  --text-primary: #1e1e1e;
  --text-secondary: #c2e8f9;
  --text-gray: #4f4f4f;

  --input-color: #1e1e1e;
  --menu-active-icon-color: #0299DE;
  --info-color: #02B0FF;

  --disabled-color: #e2e2e2;
  --card-color: #eeeeee;

  --modal-border-color: #adb5bd;
  --border-color: #0276ab;

  --card-button-navigation-color:#02b0ff;
  --card-button-color:#02b0ff;
  --card-button-border-color:#02b0ff;
  --card-bg-icon-color:#02b0ff;
  --card-text-primary-color:#02b0ff;
  --card-bg-primary-color:#02b0ff;

}
